<template>
  <div class="handelPopupBox">
    <div class="contentBox">
      <div class="handelPopupBox-titleBox">
        <div>{{title}}</div>
        <img src="@assets/img/index/icon-close2.png" @click="$emit('closepopup')" alt="">
      </div>
      <div class="handelPopupBox-contentBox">
        <div class="content">
  <!--        :defaultExpandedKeys="defaultExpandedKeys"-->
  <!--        accordion-->
<!--          -->
<!--          :render-content="renderContent"-->
          <el-tree
              ref="tree"
              :default-expanded-keys="defaultExpandedKeys"
              :highlight-current="true"
              :data="treeList"
              @node-click="getNode"
              :props="defaultProps"
              :current-node-key="currentKey"
              node-key="id"
          >
             <span class="custom-tree-node" style="display:flex;align-items: center;" slot-scope="{node,data }"  >
               <div class="item-box" v-if="!data.inputState" >
                 <img style="width: 11px;height: 7px;margin-right: 8px"   src="@assets/img/profile/icon-grid-folder.png" />
                 <span style="width: 100%">{{data.name}}</span>
               </div>
               <div  v-if="data.inputState"  class="pan-list__file-name-edit">
                   <img src="@assets/img/profile/icon-grid-folder.png"/>
                   <input type="text" v-model.trim="data.filename" autocomplete="off"/>
                   <img src="@assets/img/profile/icon-confirm.png" style="cursor:pointer;margin-left:15px;" @click="addFolder(data)"  />
                   <img src="@assets/img/profile/icon-close.png"   style="cursor:pointer;margin-left:15px;" @click="remove(node)" />
               </div>

             </span>
          </el-tree>

        </div>
        <div class="bottomBox">
          <div class="newfile" @click="append">新建文件夹</div>
          <div class="btnBox">
            <button class="btn" @click="setData">确定</button>
            <button class="btn1" @click="$emit('closepopup')">取消</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getPanList} from "@assets/api/home/home";
import {createFolder, getPanFolderList} from "@assets/api/myFiles";

export default {
  name: "handelPopup",
  data(){
    return {
      defaultProps: {
        label: 'name',
        isLeaf: 'leaf'
      },
      treeList:[
        {
          name:'根目录',
          id:'根目录',
          children:[],
        },
      ],
      popupData:{},
      defaultExpandedKeys:[],
      filename:'',
      isRename:false,
      prev_id:'',
      folderPath:'',
      node:'',
      lazy:true,
      index:0,
      currentKey:'',
      rootNode:'',//根目录
      resolve:'',
      treeState: false,
    }
  },
  props:['title'],
  created(){
    this.getTreeList()
  },
  methods: {
    getNode(data,node){
      this.node = node
      console.log('node',node.data.id)
      console.log('data',data)
      this.popupData = node.data
      let that = this
      this.folderPath = node.data.id
      if(node.data.inputState)return false
      // //当初tree中包含一个新建文件夹节点时(stateInput = true) 在tree中任何点击事件都会删除该节点
      function deleteNode(data){
        for(let item in data.children){
          let children =  data.children[item]
          // console.log('children',children)
          //如果没有查到stateInput = true就一直查 直到childNodes等于空
          if( children.length != 0 ){
            deleteNode(children)
            if(children.inputState){
              // that.remove(children)
              // children.splice(item,1)
              data.children.splice(item,1)
              console.log('children',children)
              // children.slice(1,children)
            }
          }
         }
      }
      deleteNode(this.treeList[0])
    },
    getTreeList(){
      getPanFolderList().then(res=>{
        console.log('res',res)
        // this.treeList = res.data
        this.treeList[0].children = res.data
        this.defaultExpandedKeys.push({name:'根目录',id:'根目录'})
        this.$refs.tree.setCurrentNode({name:'根目录',id:'根目录'})
      })
    },
    //添加节点
   async append() {

     //选中的数据
     let data = this.$refs.tree.getCurrentNode()
     //选中数据的key
     let key = this.$refs.tree.getCurrentKey()
     let node = this.$refs.tree.getNode(key)
     console.log('node',node)
     this.prev_id = data.id == '根目录'? '' : data.id
     if(data.id == key && data.children === null){
       this.$set(data,'children',[])
     }

     //选中数据下的children
     let children = data.children
     let promise;
     let state = false
     if(data.inputState){
       promise =  await this.addFolder(data)
       state = promise = 0 ? false : true
     }else{
       for(let item in children){
         if(children[item].inputState){
           promise =  await this.addFolder(children[item])
           if(promise == 1){
             state = true
             this.$set(children[item],'inputState',false)

           }else{
             state = false
             this.$set(children[item],'inputState',true)
           }
           // state = true
           break
         }
       }
     }

     if(!state){
       this.index++
       this.$nextTick(()=>{
         this.defaultExpandedKeys = []
         data.children.unshift({
           name:'新建文件夹' + this.index ,
           inputState:true,
           children:[],
           filename:'新建文件夹' + this.index,
           id:'a'+ this.index,
         })
         console.log('treeList',this.treeList)
         this.defaultExpandedKeys.push('a'+this.index)
         console.log('keyS',this.defaultExpandedKeys)
       })
     }


    },
    //删除节点
    remove(node){
      this.$refs.tree.remove(node)
    },
    //新建文件夹
    addFolder(data){
      return new Promise((resolve, reject) => {
        let params = {
          name:data.filename,
          prev_id:this.prev_id,
        }
            createFolder(params).then(res=>{
              if(res.code == 1){
                this.$Message.success(`${res.msg}`)
                this.isRename = false
                data.inputState = false
                data.name = data.filename
                this.$set(data,'id',res.data.folder_id)
                data.id = res.data.folder_id
                console.log('data',data)
                // this.defaultExpandedKeys.push({name:data.name,id:data.id})
                // this.$refs.tree.setCurrentNode({name:data.name,id:data.id})
                resolve(1)

              }else{
                reject(0)
                this.$Message.error(`${res.msg}`)
              }
            })
      })
    },
    findDirectoryById(arr, id = this.folderPath) {
    for (let i = 0; i < arr.length; i++) {
        if (arr[i].id === id) {
            return [{ name: arr[i].name, id: arr[i].id }];// 返回包含当前目录的数组
        } else if (arr[i].children) {
            const result = this.findDirectoryById(arr[i].children, id);
            if (result) {
                return [{ name: arr[i].name, id: arr[i].id }, ...result]; // 返回包含当前目录及其父级目录的name和id属性的对象数组
            }
        }
    }
    return null;
    },
    setData(){
      const directories = this.findDirectoryById(this.treeList);
      let obj = {
        moveNav:directories,
        data:this.popupData,
        title:this.title,
        folder_path:this.folderPath
      }
      this.$emit('handelPopupFile',obj)
    },
  }

}

</script>

<style lang='less' scoped>

.pan-list__file-name-edit{
  //padding-left: 20px;
  img{
    width: 14px;
    vertical-align: middle;
  }
  input{
    margin: 0 0 0 5px;
    padding: 0 10px;
    width: 304px;
    height: 30px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #dcdfe6;
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #333333;
  }
  input:focus{
    border: 1px solid #3B4E99;
  }
}


/deep/.el-tree-node__content:hover{
  background: #EDF1FF;
}
/deep/.el-tree-node:focus>.el-tree-node__content{
  background: #EDF1FF;
  width: 100%;
}
/deep/.el-tree-node__content{
  height: 40px;
}
.item-box{
  display: flex;
  align-items: center;
}
.handelPopupBox{
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  top: 0;
  .contentBox{
    width: 600px;
    height: 348px;
    position: absolute;
    top: 25%;
    left: 25%;
    background: #FFFFFF;
    .handelPopupBox-titleBox{
      width: 600px;
      height: 40px;
      background: #F9F8F9;
      border-radius: 4px;
      padding: 10px 20px;
      display: flex;
      justify-content: space-between;
    }
    .handelPopupBox-contentBox{
      width: 100%;
      height: calc(100% - 40px);
      padding: 20px;
      .content{
        width: 100%;
        height: 208px;
        border: 1px solid #EBEBEB;
        overflow: scroll;
      }
      .content::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
      .bottomBox{
        width: 100%;
        height: 30px;
        margin-top: 14px;
        padding-left: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .newfile{
          width: 70px;
          height: 20px;
          font-size: 14px;
          font-family: PingFang-SC-Regular, PingFang-SC;
          font-weight: 400;
          color: #009CF5;
          line-height: 20px;
          cursor: pointer;
        }
        .btnBox{
          width: 140px;
          display: flex;
          justify-content: space-between;
          .btn{
            width: 60px;
            height: 30px;
            background: linear-gradient(143deg, #22DEB5 0%, #06A7F0 100%);
            border-radius: 2px;
            font-size: 14px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #FFFFFF;
          }
          .btn1{
            font-size: 14px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #06A7F0;
            width: 60px;
            height: 30px;
            background: rgba(255,255,255,0.6);
            border-radius: 2px;
            border: 1px solid;
            border-image: linear-gradient(180deg, rgba(34, 222, 181, 1), rgba(6, 167, 240, 1)) 1 1;
          }
        }
      }
    }
  }
}

</style>