<template>
  <transition name="el-fade-in-linear">
    <div v-show="show" class="previewFileBox">
    <div class="previewFile-content">
      <div class="previewFile-content-title">
        <div class="fileType">
          <img :src="getImgType(fileInfo.suffix)" alt="">
          <span>{{fileInfo.name}}</span>
        </div>
<!--        $emit('close')-->
        <img class="close" @click="close" src="@assets/img/icon-close.png" alt="">
      </div>
      <div class="previewFile-content-body"  @wheel="handelWheel">
        <!--  pdf文档    -->
        <div class="pdfBox" v-if="fileInfo.suffix == 'pdf'" >
          <pdf
              ref="pdf"
              v-for="i in pdf.pageTotalNum" :key="i"
              :page="i"
              :src="fileUrl"
          >
          </pdf>
        </div>
        <!--  word文档和docx文档      -->
        <div class="wordBox" v-if="fileInfo.suffix == 'docx'">
          <div ref="word"></div>
        </div>
        <div class="wordBox" v-if="fileInfo.suffix == 'doc'">
          <iframe v-if="doc" frameborder="0"
                  :src="doc"
                  width='100%'
                  height="100%">
          </iframe>
        </div>
        <!--   图片  -->
        <div class="imageBox" v-if="fileInfo.suffix == 'png' || fileInfo.suffix == 'jpg' || fileInfo.suffix == 'jpeg' ">
            <img :src="fileUrl" :style="{width:width + '%',height:height + '%',transform:'rotate(' + rotateNum +'deg)'}" alt="">
          <div class="tools">
            <div class="num">
              <i @click="increase" class="newiconfont icon-zengjiashuzi"></i>
              <span>{{width + '%'}}</span>
              <i @click="reduce" class="newiconfont icon-jianshaoshuzi"></i>
            </div>
            <div class="rotate">
              <i @click="rotateImg('left')" class="newiconfont icon-xuanzhuan-2"></i>
              <i @click="rotateImg('right')" class="newiconfont icon-xuanzhuan-1"></i>
            </div>
          </div>
        </div>
        <!--   音频  -->
        <div class="mp3Box" v-if="fileInfo.suffix == 'mp3'">
          <audio autoplay controls :src="fileUrl" ref="audio"></audio>
        </div>
        <!--   视频-->
        <div v-if="fileInfo.suffix == 'mp4'" style="width: 100%;height: 100%">
          <vue-core-video-player
              v-if="videoUrl"
              :src="videoUrl"
              ref="videoPlayer"
          />
        </div>
      </div>
    </div>
  </div>
  </transition>
</template>

<script>
import pdf from 'vue-pdf'
import {getImgType} from '../utils/function'
import Aplayer from 'vue-aplayer'
import axios from "axios";
Aplayer.disableVersionBadge = true

var docxx = require("docx-preview");

export default {
  name: "previewFile",
  props:['showPreviewFile','fileInfo','fileUrl','fileType'],
  components:{
    pdf,
    Aplayer,
  },
  data(){
    return{
      show:this.showPreviewFile,
      width: 50,
      height:100,
      rotateNum:0,
      pdf:{
        // 总页数
        pageTotalNum: 1,
        // 当前页数
        pageNum: 1,
        // 加载进度
        loadedRatio: 0,
        // 页面加载完成
        curPageNum: 0,
        // 放大系数 默认百分百
        scale: 100,
        // 旋转角度 ‘90’的倍数才有效
        pageRotate: 0,
        // 单击内部链接时触发 (目前我没有遇到使用场景)
        page: 0,
      },
      //视频播放状态
      videoState:false,
      videoUrl:this.fileUrl,
      //音频
      music:
        {
          title: '',
          artist: '',
          src: this.fileUrl,
          pic: ''
        },
      doc:'',

    }
  },
  watch:{
    showPreviewFile(newVal){
      if(newVal && this.fileType == 'pdf'){
        this.getNumPages()
      }
      //再次打开视频 播放视频
      if(newVal && this.fileType == 'mp4' ){
        this.videoUrl = this.fileUrl
        if(this.videoUrl){
          this.$refs.videoPlayer.$player.play()
        }
        // this.$refs.videoPlayer.$player.play()
      }
      if(newVal && this.fileType == 'mp3'){
        console.log('fileUrl',this.fileUrl)
        this.$set(this.music,'src',this.fileUrl)
        this.$set(this.music,'title',this.fileInfo.name)
        this.$set(this.music,'pic',getImgType(this.fileInfo.suffix))
      }
      if(newVal && this.fileInfo.suffix == 'docx' ){

        axios.get(this.fileUrl,{
          responseType: "blob",
        }).then(res=>{
          console.log('res',res)
          docxx.renderAsync(res.data, this.$refs.word);
        })
      }
      if(newVal && this.fileInfo.suffix == 'doc'){
        let url = encodeURIComponent(this.fileUrl)
        // let officeUrl = 'http://view.officeapps.live.com/op/view.aspx?src=' + url
        this.doc = 'http://view.officeapps.live.com/op/view.aspx?src=' + url

        // window.open(officeUrl,'_target')
      }
    },
  },
  methods:{
    //关闭弹窗时 暂停视频 关闭音频
    close(){
      if(this.fileType == 'mp4'){
        this.$refs.videoPlayer.$player.pause()
        this.videoState = true
      }
      if(this.fileType == 'mp3'){
        this.$nextTick(()=>{
          // this.$refs.aplayer.pause()
          this.$refs.audio.pause()
          // this.$set(this.music,'src','')
          this.fileUrl = ''
        })
      }
      this.$emit('close')
    },

    //滚轮操作
    handelWheel(e){
      if(e.wheelDelta > 0){
        this.width = this.width + 10
        this.height = this.height + 10
      }else{
        this.width = this.width - 10
        this.height = this.height - 10
      }
      if(this.width < 20){
        this.width = 20
        this.height = 20
        return false
      }

    },
    //增加
    increase(){
      this.width = this.width + 10
      this.height = this.height + 10
    },
    //减少
    reduce(){
      if(this.width >20){
        this.width = this.width - 10
        this.height = this.height - 10
      }

    },
    //旋转
    rotateImg(type){
      if(type == 'left'){
        this.rotateNum = this.rotateNum + 90;
      }else{
        this.rotateNum = this.rotateNum - 90;
      }
    },
    //获取pdf所有页面
    getNumPages(){
      let loadingTask = pdf.createLoadingTask(this.fileUrl)
      loadingTask.promise.then(pdf => {
        this.pdf.pageTotalNum = pdf.numPages
      })
    },
  }
}
</script>

<style scoped lang="less">
  .previewFileBox{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.2);

    .previewFile-content{
      width: calc(100% - 233px);
      margin: 0 auto;
      height: 100%;
      .previewFile-content-title{
        height: 60px;
        line-height: 60px;
        padding: 0 20px;
        background: #000;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .fileType{
          width: 200px;
          height: 100%;
          display: flex;
          align-items: center;
          img{
            width: 30px;
            height: 30px;
          }
          span{
            margin-left: 15px;
            font-size: 14px;
            font-family: PingFang-SC-Regular;
            color: #fff;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

        }
        .close{
          width: 40px;
          height: 40px;
        }
      }
      .previewFile-content-body{
        width: 100%;
        height: calc(100% - 60px);
        background: #FFFFFF;
        overflow: hidden;
        .pdfBox{
          overflow: auto;
          height: 100%;
          .pdf-tools{
            width: 50%;
            position: absolute;
            background: rgba(0,0,0,0.2);
            bottom: 20px;
          }
        }
        .wordBox{
          overflow: auto;
          height: 100%;
        }
        //图片
        .imageBox{
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          img{
            transition: all 0.3s ease-in-out;
            object-fit: contain;
          }
          .tools{
            width: 300px;
            height: 60px;
            margin: 0 auto;
            background-color: rgba(0,0,0,.85);
            border-radius: 4px;
            font-size: 0;
            position: absolute;
            bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            .num{
              display: flex;
              height: 60px;
              width: 140px;
              align-items: center;
              justify-content: space-around;
              span{
                font-size: 20px;
                color: white;
              }
            }
            .rotate{
              width: 100px;
              display: flex;
              justify-content: space-around;
              align-items: center;
            }
          }
        }
        //音频
        .mp3Box{
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
      }
    }
  }
  video::-webkit-media-controls-overlay-play-button { //隐藏android端点击control中暂停按钮时视频中心出现的play icon
    display: none;
  }
  /deep/.play-pause-layer{
    background: none !important;
    display: none !important;

  }
</style>